import { format, parseISO } from 'date-fns'

export const getLocalizedDateTimeWithTimezoneInfo = (dateTimeISOString: string) => {
  const { localizedDeadlineTime, offsetString } =
    extractLocalizedTimestampWithOffset(dateTimeISOString)

  return `${format(localizedDeadlineTime, 'yyyy-MM-dd HH:mm')} [${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  } ${offsetString}]`
}

const extractLocalizedTimestampWithOffset = (dateTimeISOString: string) => {
  const localizedDeadlineTime = parseISO(dateTimeISOString)
  const timezoneOffset = -localizedDeadlineTime.getTimezoneOffset() / 60
  const offsetString = timezoneOffset >= 0 ? `GMT+${timezoneOffset}` : `GMT${timezoneOffset}`

  return { localizedDeadlineTime, offsetString }
}

export const getLocalizedDateTimeWithGivenFormat = (
  dateTimeISOString: string,
  formatDescriptor: string | null,
  locale?: string,
) => {
  if (formatDescriptor?.trim()) {
    return format(new Date(dateTimeISOString), formatDescriptor)
  }

  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
    timeStyle: 'short',
  }).format(new Date(dateTimeISOString))
}
