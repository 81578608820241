import { AppEnvEnum, Environment } from 'typedef/Environment'
import { sharedEnvironment } from './environment-shared'

export const environment: Environment = {
  ...sharedEnvironment,
  NODE_ENV: AppEnvEnum.PROD,
  REGION: 'us',
  REACT_APP_API_URL: 'https://api.pactum.com/api/v1',
  REACT_APP_AUTH0_DOMAIN: 'login.pactum.com',
  REACT_APP_AUTH0_CLIENT: '0a91TAPg29lkJIsagM1zaEDL57M1gAuv',
  REACT_APP_AUTH0_BACKEND_API: 'https://api.pactum.com/api/v1',
  REACT_APP_ACCESS_TOKEN_COOKIE: 'pactumAccessToken',
  REACT_APP_NEGOTIATION_DESIGNER_API_BASE_URL: 'https://flow.pactum.com',
  REACT_APP_TURNSTILE_SITE_KEY: '0x4AAAAAAANROms3cS_9htx6',
}
