import { SupportedChatLocale } from '@pactum/common'
import translationsBgBg from './translations_bg-BG.json'
import translationsCsCz from './translations_cs-CZ.json'
import translationsDeDe from './translations_de-DE.json'
import translationsEnUs from './translations_en-US.json'
import translationsEsCl from './translations_es-CL.json'
import translationsEsCr from './translations_es-CR.json'
import translationsEsEs from './translations_es-ES.json'
import translationsEsMx from './translations_es-MX.json'
import translationsEtEE from './translations_et-EE.json'
import translationsFrFr from './translations_fr-FR.json'
import translationsHuHu from './translations_hu-HU.json'
import translationsIdId from './translations_id-ID.json'
import translationsItIt from './translations_it-IT.json'
import translationsJaJp from './translations_ja-JP.json'
import translationsKoKr from './translations_ko-KR.json'
import translationsLtLt from './translations_lt-LT.json'
import translationsLvLv from './translations_lv-LV.json'
import translationsNlNl from './translations_nl-NL.json'
import translationsPlPl from './translations_pl-PL.json'
import translationsPtPt from './translations_pt-PT.json'
import translationsRoRo from './translations_ro-RO.json'
import translationsSvSe from './translations_sv-SE.json'
import translationsTrTr from './translations_tr-TR.json'
import translationsZhHansCN from './translations_zh-Hans-CN.json'
import translationsZhHantTW from './translations_zh-Hant-TW.json'

export type TranslationKeys = keyof typeof translationsEnUs
export type Translations = Record<TranslationKeys, string>
type TranslationOverrides = Partial<Translations>

export const defaultTranslations: Record<SupportedChatLocale, Translations> = {
  [SupportedChatLocale.bgBG]: translationsBgBg,
  [SupportedChatLocale.csCZ]: translationsCsCz,
  [SupportedChatLocale.deDE]: translationsDeDe,
  [SupportedChatLocale.enUS]: translationsEnUs,
  [SupportedChatLocale.esCL]: translationsEsCl,
  [SupportedChatLocale.esCR]: translationsEsCr,
  [SupportedChatLocale.esES]: translationsEsEs,
  [SupportedChatLocale.esMX]: translationsEsMx,
  [SupportedChatLocale.etEE]: translationsEtEE,
  [SupportedChatLocale.frFR]: translationsFrFr,
  [SupportedChatLocale.huHU]: translationsHuHu,
  [SupportedChatLocale.idID]: translationsIdId,
  [SupportedChatLocale.itIT]: translationsItIt,
  [SupportedChatLocale.jaJP]: translationsJaJp,
  [SupportedChatLocale.koKR]: translationsKoKr,
  [SupportedChatLocale.ltLT]: translationsLtLt,
  [SupportedChatLocale.lvLV]: translationsLvLv,
  [SupportedChatLocale.nlNL]: translationsNlNl,
  [SupportedChatLocale.plPL]: translationsPlPl,
  [SupportedChatLocale.ptPT]: translationsPtPt,
  [SupportedChatLocale.roRO]: translationsRoRo,
  [SupportedChatLocale.svSE]: translationsSvSe,
  [SupportedChatLocale.trTR]: translationsTrTr,
  [SupportedChatLocale.zhHansCN]: translationsZhHansCN,
  [SupportedChatLocale.zhHantTW]: translationsZhHantTW,
} as const

export type ChatParamsLocalisation = TranslationOverrides &
  Partial<Record<SupportedChatLocale, TranslationOverrides>>

export const isSupportedLocale = (locale: string): locale is SupportedChatLocale => {
  return Object.keys(defaultTranslations).includes(locale)
}
