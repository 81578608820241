import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, BoxProps, Stack, styled, Typography } from '@mui/material'

import { ChatUrlParams } from '@constants/routes'
import { useChatParams, useIsReadOnlyChatSession } from 'store/selectors'
import { replaceApiPlaceholdersInHtml } from '@utils/apiPlaceholders'
import {
  getStyledChatDefinitionListClasses,
  getStyledChatTableClasses,
} from '@components/ChatMessage/legacyStyleDefinitions'
import { HTML } from '@components/HTML'
import { getLocalizedDateTimeWithGivenFormat } from '@utils/datetime'
import { useDebugToolbar } from '@context/DebugToolbar'
import { useChatContext } from '@pages/ChatContext'

interface Props {
  message: string | React.ReactElement
  useUserMessageStyles: boolean
  fullWidth: boolean
  timestamp?: string
}

export const ChatMessageBubble = React.forwardRef(
  ({ message, useUserMessageStyles, fullWidth, timestamp }: Props, ref): JSX.Element | null => {
    const urlParams = useParams<ChatUrlParams>()
    const chatParams = useChatParams()
    const chatContext = useChatContext()
    const { open: debugToolbarOpen } = useDebugToolbar()
    const isReadOnlyChatSession = useIsReadOnlyChatSession()

    if (!chatParams) {
      return null
    }

    const getHtmlMessage = (rawMessage: string): string => {
      return replaceApiPlaceholdersInHtml(
        rawMessage.replace(/\n/g, '<br />'),
        chatParams,
        urlParams.token,
      )
    }

    const hasAllKeywords = (message: unknown, keywords: string[]) => {
      if (typeof message === 'string') {
        const lowerMessage = message.toLowerCase()
        return keywords.every((keyword) => lowerMessage.includes(keyword))
      }
      return false
    }

    const hasSomeKeywords = (message: unknown, keywords: string[]) => {
      if (typeof message === 'string') {
        const lowerMessage = message.toLowerCase()
        return keywords.some((keyword) => lowerMessage.includes(keyword))
      }
      return false
    }

    return (
      <TimestampedChatMessageBubbleContainer
        isFullWidth={fullWidth}
        useUserMessageStyles={useUserMessageStyles}
      >
        <ChatMessageBubbleContainer
          ref={ref}
          useUserMessageStyles={useUserMessageStyles}
          isFullWidth={fullWidth}
          isTableBubble={hasAllKeywords(message, ['<table>', '</table>'])}
          isStyledChatTableBubble={hasAllKeywords(message, ['styled-chat-table'])}
          isBubbleWithoutSpacing={hasSomeKeywords(message, ['bubble-without-spacing'])}
          isPactumTableBubble={hasSomeKeywords(message, ['<pactum-table'])}
        >
          <MessageText variant='body2' component='div'>
            {typeof message === 'string' ? <HTML markup={getHtmlMessage(message)} /> : message}
          </MessageText>
        </ChatMessageBubbleContainer>
        {timestamp && (debugToolbarOpen || isReadOnlyChatSession) ? (
          <MessageTimestamp>
            {getLocalizedDateTimeWithGivenFormat(
              timestamp,
              chatParams.dateFormatDescriptor,
              chatContext?.locale,
            )}
          </MessageTimestamp>
        ) : null}
      </TimestampedChatMessageBubbleContainer>
    )
  },
)

export const MessageText = styled(Typography)(({ theme }) => ({
  fontSize: '0.9375rem',
  lineHeight: 1.6,
  '& ul': {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    paddingInlineStart: 20,
    lineHeight: 1.3,
  },
})) as typeof Typography

export const MessageTimestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.5rem',
  color: theme.palette.grey[600],
  lineHeight: 1,
  textAlign: 'right',
  marginTop: theme.spacing(-0.5),
  marginBottom: theme.spacing(1),
}))

type StyleProps = BoxProps & {
  useUserMessageStyles: boolean
  isTableBubble: boolean
  isStyledChatTableBubble: boolean
  isBubbleWithoutSpacing: boolean
  isPactumTableBubble: boolean
  isFullWidth: boolean
}

const ChatMessageBubbleContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'useUserMessageStyles' &&
    prop !== 'isTableBubble' &&
    prop !== 'isStyledChatTableBubble' &&
    prop !== 'isBubbleWithoutSpacing' &&
    prop !== 'isPactumTableBubble' &&
    prop !== 'isFullWidth',
})<StyleProps>(
  ({
    theme,
    useUserMessageStyles,
    isTableBubble,
    isStyledChatTableBubble,
    isBubbleWithoutSpacing,
    isPactumTableBubble,
    isFullWidth,
  }) => ({
    display: 'inline-block',
    maxWidth: '100%',
    margin: theme.spacing(0, 0, 1.5, 0),
    padding: theme.spacing(1.5, 3, 1.5, 2),
    lineHeight: 1.5,
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    boxShadow: '0px 4px 21px 8px rgba(0, 0, 0, 0.04)',
    borderRadius: '0px 9px 9px 9px',

    ...getStyledChatDefinitionListClasses(theme),
    ...getStyledChatTableClasses(theme),

    ...(isFullWidth
      ? {
          width: '100%',
          [theme.breakpoints.up('lg')]: {
            width: 'calc(100% - 80px)',
          },
        }
      : {}),

    ...(useUserMessageStyles
      ? {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderRadius: '9px 0px 9px 9px',
        }
      : {}),
    ...(isTableBubble ? { whiteSpace: 'unset', padding: '20px' } : {}),
    ...(isStyledChatTableBubble ? { whiteSpace: 'unset', padding: 0 } : {}),
    ...(isBubbleWithoutSpacing ? { padding: 0 } : {}),
    ...(isPactumTableBubble ? { padding: 0, backgroundColor: 'transparent' } : {}),
  }),
)

const TimestampedChatMessageBubbleContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isFullWidth' && prop !== 'useUserMessageStyles',
})<{ isFullWidth: boolean; useUserMessageStyles: boolean }>(
  ({ theme, isFullWidth, useUserMessageStyles }) => ({
    alignItems: useUserMessageStyles ? 'flex-end' : 'flex-start',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'calc(100% - 80px)',
    },
    ...(isFullWidth
      ? {
          width: '100%',
          [theme.breakpoints.up('lg')]: {
            width: 'calc(100% - 80px)',
          },
        }
      : {}),
  }),
)
