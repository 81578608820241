import React from 'react'
import { Element } from 'domhandler'
import { useChatContext } from '@pages/ChatContext'
import { getLocalizedDateTimeWithGivenFormat } from '@utils/datetime'

const UnknownValue = () => {
  return <span>N/A</span>
}

export const LocalizedDatetimeReplace = (element: Element) => {
  const datetime = element.attribs['data-datetime']
  const dateFormatDescriptor = element.attribs['data-date-format-descriptor']
  if (!datetime) {
    return <UnknownValue />
  }
  return <LocalizedDateTimeString datetime={datetime} formatDescriptor={dateFormatDescriptor} />
}

export const LocalizedDateTimeString = ({
  datetime,
  formatDescriptor,
}: {
  datetime: string
  formatDescriptor: string | null
}) => {
  const chatContext = useChatContext()

  let formattedDate
  try {
    formattedDate = getLocalizedDateTimeWithGivenFormat(
      datetime,
      formatDescriptor,
      chatContext?.locale,
    )
  } catch {
    return <UnknownValue />
  }

  return <span>{formattedDate}</span>
}
