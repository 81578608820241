export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return '0 B'
  }

  const sizes = ['B', 'KB', 'MB', 'GB']
  const base = 1024
  const i = Math.floor(Math.log(bytes) / Math.log(base))

  return `${parseFloat((bytes / Math.pow(base, i)).toFixed(decimals))} ${sizes[i]}`
}
